// src/pages/Home.tsx
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Typography, Button } from "@mui/material";
import softwareImage from "../../assets/banner.png";
import logoHelmet from "../../assets/logo-9BR.png";
import "./Home.scss";
import InfoCircles from "../InfoCircles";

const Home: React.FC = () => {
  return (
    <Container className="home-container">
      <Helmet>
        <title>9Bricks - Innovating Everyday Solutions with AI</title>

        <meta
          name="description"
          content="9Bricks helps businesses and individuals innovate through AI-driven solutions like Tavis, the personal assistant for everyday tasks. Discover a new era of productivity with us!"
        />

        <meta
          property="og:title"
          content="9Bricks - Innovating Everyday Solutions with AI"
        />
        <meta
          property="og:description"
          content="Explore 9Bricks' solutions, including Tavis, an AI-powered personal assistant. Achieve a balance between productivity and creativity!"
        />
        <meta property="og:image" content={logoHelmet} />
        <meta property="og:url" content="https://9bricks.com" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="9Bricks - Innovating Everyday Solutions with AI"
        />
        <meta
          name="twitter:description"
          content="Discover how 9Bricks redefines productivity with AI-powered tools like Tavis. Transforming daily routines with intelligent solutions."
        />
        <meta name="twitter:image" content={logoHelmet} />
      </Helmet>
      <div className="banner-container">
        <img
          src={softwareImage}
          alt="Connecting People"
          className="banner-image"
        />
        <div className="banner-text">
          <Typography variant="h4" className="home-title">
            Hello world! We’re <span className="highlight">#9Bricks</span>
          </Typography>
          <Typography variant="h6" className="home-subtitle">
            Creating novelty from the familiar
          </Typography>
        </div>
      </div>

      <Typography variant="body1" className="home-content">
        At <span className="highlight">9Bricks</span>, we build new
        possibilities on the foundation of the familiar. We connect virtual,
        digital, and analog worlds to create innovative experiences that feel
        intuitive and transformative. Our goal is to simplify complex processes,
        digitize workflows, and design unique software solutions that seamlessly
        blend into your everyday.
      </Typography>

      <InfoCircles />

      <div className="home-additional-text">
        <Typography variant="body2">
          We combine advanced technology with a human-centered approach to
          create solutions that resonate with people’s daily lives. At 9Bricks,
          we believe that true innovation is about reimagining the ordinary and
          making the complex feel effortless.
        </Typography>
        <Typography variant="body2">
          With a strong foundation in both creativity and technical expertise,
          we build tools that empower businesses, simplify experiences, and make
          a meaningful impact. Join us as we bridge the familiar and the future.
        </Typography>
      </div>

      <Button
        variant="contained"
        className="home-contact-button"
        component={Link}
        to="/contact"
      >
        Let’s talk!
      </Button>
    </Container>
  );
};

export default Home;
