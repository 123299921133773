import React from "react";
import { Container, Typography } from "@mui/material";
import "./Insights.scss";

const Insights: React.FC = () => {
  return (
    <Container className="insights-container">
      <Typography variant="h4" className="insights-title">
        Insights: The Power of Software and AI in Modern Life
      </Typography>

      <section className="insights-section">
        <Typography variant="h5" className="insights-subtitle">
          Software Development: Building the Digital World
        </Typography>
        <Typography variant="body1" className="insights-content">
          Software is the backbone of the digital world. It powers businesses, connects people, and drives innovation. At 9Bricks, we believe that great software combines cutting-edge technology with a deep understanding of human needs.
        </Typography>
        <Typography variant="body1" className="insights-content">
          Key insights include Agile methodologies, automation, and user-centered design. Trends like microservices and low-code/no-code solutions are shaping the future of development.
        </Typography>
      </section>

      <section className="insights-section">
        <Typography variant="h5" className="insights-subtitle">
          AI: Enhancing Everyday Life
        </Typography>
        <Typography variant="body1" className="insights-content">
          Artificial Intelligence is now a part of our daily lives. At 9Bricks, we’re harnessing AI to improve efficiency and create personalized experiences, from smart assistants like Tavis to predictive analytics and natural language processing.
        </Typography>
        <Typography variant="body1" className="insights-content">
          Looking ahead, we focus on ethical AI and human-AI collaboration to ensure responsible development and transformative impact.
        </Typography>
      </section>

      <Typography variant="body1" className="insights-conclusion">
        The intersection of software development and AI offers endless possibilities. At 9Bricks, we’re committed to exploring these opportunities to bring innovative solutions that enhance lives and businesses.
      </Typography>
    </Container>
  );
};

export default Insights;
