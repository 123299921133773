// src/components/Footer.tsx
import React from "react";
import { Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <Container className="footer-container">
        {/* Thông tin công ty */}
        <div className="footer-column">
          <Typography variant="h6">9Bricks</Typography>
          <Typography variant="body2">
            {/* 123 Business Avenue <br /> */}
            Address: District 12, Ho Chi Minh City, Vietnam <br />
            Phone: +84 765 558 058 <br />
            Email: support@9bricks.com
          </Typography>
        </div>

        {/* Expertise Links */}
        <div className="footer-column">
          <Typography variant="h6">Expertise</Typography>
          <Typography
            variant="body2"
            component={Link}
            to="/software-solutions"
            className="footer-link"
          >
            Software Solutions
          </Typography>
        </div>

        <div className="footer-column">
          <Typography variant="h6">Products</Typography>
          <Typography
            variant="body2"
            component={Link}
            to="/products"
            className="footer-link"
          >
            TAVIS Personal Assistant
          </Typography>
        </div>

        {/* Resources Links */}
        <div className="footer-column">
          <Typography variant="h6">Resources</Typography>
          <Typography
            variant="body2"
            component={Link}
            to="/our-journey"
            className="footer-link"
          >
            Our Journey
          </Typography>
          <Typography
            variant="body2"
            component={Link}
            to="/insights"
            className="footer-link"
          >
            Insights
          </Typography>
          <Typography
            variant="body2"
            component={Link}
            to="/jobs"
            className="footer-link"
          >
            Jobs
          </Typography>
          <Typography
            variant="body2"
            component={Link}
            to="/about-us"
            className="footer-link"
          >
            About Us
          </Typography>
        </div>

        {/* Legal Links */}
        <div className="footer-column">
          <Typography variant="h6">Legal</Typography>
          <Typography variant="body2" component={Link} to="/privacy-policy" className="footer-link">
            Privacy Policy
          </Typography>
          <Typography variant="body2" component={Link} to="/cookie-policy" className="footer-link">
            Cookie Policy
          </Typography>
          <Typography variant="body2" component={Link} to="/terms-and-conditions" className="footer-link">
            Terms and conditions
          </Typography>
        </div>

        {/* Contact Links */}
        {/* <div className="footer-column">
          <Typography variant="h6">Contact</Typography>
          <Typography variant="body2" component={Link} to="/contact" className="footer-link">
            Contact Form
          </Typography>
          <Typography variant="body2" component={Link} to="/linkedin" className="footer-link">
            LinkedIn
          </Typography>
        </div> */}
      </Container>
      {/* Divider */}
      <div className="footer-divider"></div>
      <Typography variant="body2" className="footer-bottom">
        © 2024 9Bricks | Ho Chi Minh City
      </Typography>
    </footer>
  );
};

export default Footer;
