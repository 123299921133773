// src/pages/Contact.tsx
import React from "react";
import { Container, Typography, Box, TextField, Button } from "@mui/material";
import "./Contact.scss";

const Contact: React.FC = () => {
  return (
    <Container className="contact-container">
      <Typography variant="h4" className="title">Contact Us</Typography>
      <Typography variant="body1" className="intro">
        We’d love to hear from you. Feel free to reach out to us using the contact information below or send us a message through the form.
      </Typography>

      {/* Contact Information */}
      <Box className="contact-info">
        <Typography variant="h6">Our Office</Typography>
        <Typography variant="body2">Address: District 12, Ho Chi Minh City, Vietnam</Typography>
        <Typography variant="body2">Phone: +84 765 558 058</Typography>
        <Typography variant="body2">Email: support@9bricks.com</Typography>
      </Box>

      {/* Contact Form */}
      <Box component="form" className="contact-form">
        <Typography variant="h6">Send Us a Message</Typography>
        <TextField label="Your Name" variant="outlined" fullWidth margin="normal" required />
        <TextField label="Your Email" variant="outlined" fullWidth margin="normal" required type="email" />
        <TextField label="Subject" variant="outlined" fullWidth margin="normal" />
        <TextField label="Message" variant="outlined" fullWidth margin="normal" multiline rows={4} required />
        <Button variant="contained" color="primary" className="submit-button">
          Send Message
        </Button>
      </Box>
    </Container>
  );
};

export default Contact;
