// src/pages/AboutUs.tsx
import React from "react";
import { Container, Typography } from "@mui/material";
import "./AboutUs.scss";

const AboutUs: React.FC = () => {
  return (
    <>
      <Container className="about-us-container">
        <Typography variant="h3" className="about-us-title">
          About Us
        </Typography>
        <Typography variant="body1" className="about-us-content">
          At 9Bricks, we are committed to building connections and simplifying
          lives through cutting-edge technology. Established in 2019, our
          company has experienced significant growth since the beginning of
          2023. Our focus lies in affiliate marketing and SaaS-based software
          development, creating innovative solutions that drive digital
          transformation.
        </Typography>
        <Typography variant="body1" className="about-us-content">
          Join us on our journey as we continue to shape the future of
          technology, helping businesses and individuals thrive in the digital
          age.
        </Typography>
      </Container>
    </>
  );
};

export default AboutUs;
