import React from "react";
import { Container, Typography } from "@mui/material";
import "./OurJourney.scss";

const OurJourney: React.FC = () => {
  return (
    <Container className="journey-container">
      <Typography variant="h3" className="title">
        Our Journey: A Path of Meaningful Growth
      </Typography>

      <section className="journey-section">
        <Typography variant="body1" className="content">
          The journey of <strong>9Bricks</strong> is one of steady growth,
          learning, and meaningful contributions. From starting as a small
          software outsourcing team to providing innovative solutions for local
          F&B businesses, we’ve always stayed focused on creating value for our
          partners and clients.
        </Typography>
      </section>

      <section className="journey-section">
        <Typography variant="h5" className="subtitle">
          Building Our Foundation
        </Typography>
        <Typography variant="body1" className="content">
          9Bricks began as a <strong>small-scale software outsourcing company</strong>, collaborating with clients to deliver tailored solutions. With a passion for coding and a commitment to quality, we specialized in helping small and medium-sized businesses streamline their operations through technology.
        </Typography>
      </section>

      <section className="journey-section">
        <Typography variant="h5" className="subtitle">
          Exploring the F&B Industry
        </Typography>
        <Typography variant="body1" className="content">
          In 2019, we took our first steps beyond outsourcing and entered the <strong>Food and Beverage (F&B) industry</strong>. Starting with a small initiative centered on <strong>Bánh Mì Việt Nam</strong>, we gained hands-on experience in the challenges faced by F&B operators.
        </Typography>
      </section>

      <section className="journey-section">
        <Typography variant="h5" className="subtitle">
          Providing Practical F&B Solutions
        </Typography>
        <Typography variant="body1" className="content">
          Guided by our firsthand experience, we started crafting <strong>software solutions</strong> for F&B businesses in our community. These tools simplified workflows, supported inventory management, and enabled better customer engagement.
        </Typography>
      </section>

      <section className="journey-section">
        <Typography variant="h5" className="subtitle">
          Introducing TAVIS: A Step Toward SaaS
        </Typography>
        <Typography variant="body1" className="content">
          Building on our success in F&B, we began work on <strong>TAVIS</strong>, an AI-assisted SaaS platform aimed at simplifying everyday business operations. Though still in its early stages, TAVIS represents our belief that even small tools can make a big difference.
        </Typography>
      </section>

      <section className="journey-section">
        <Typography variant="body1" className="content">
          At <strong>9Bricks</strong>, we remain committed to meaningful, small-scale innovation. By staying grounded in our values and focusing on the communities we serve, we aim to keep building solutions that matter—one step at a time.
        </Typography>
      </section>
    </Container>
  );
};

export default OurJourney;
