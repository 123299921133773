// src/pages/TermsAndConditions.tsx
import React from "react";
import { Container, Typography } from "@mui/material";
import "./TermsAndConditions.scss";

const TermsAndConditions: React.FC = () => {
  return (
    <Container className="terms-container">
      <Typography variant="h4" className="title">Terms and Conditions</Typography>

      <section className="section">
        <Typography variant="h6" className="section-title">1. Introduction</Typography>
        <Typography variant="body1" className="section-content">
          Welcome to 9Bricks. By accessing or using our website and services, you agree to be bound by the following terms and conditions. If you do not agree with any part of these terms, you may not access the website or use any services.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">2. Intellectual Property Rights</Typography>
        <Typography variant="body1" className="section-content">
          All content, design, and intellectual property displayed on this website, including but not limited to text, graphics, logos, icons, images, and software, are owned by 9Bricks or its content suppliers and are protected by intellectual property laws. Any unauthorized use of the content may violate copyright, trademark, and other laws.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">3. User Obligations</Typography>
        <Typography variant="body1" className="section-content">
          By accessing this website, you agree to use it only for lawful purposes. You are prohibited from violating or attempting to violate the security of the site, including but not limited to accessing data not intended for you, breaching authentication measures, or disrupting service to other users.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">4. Limitation of Liability</Typography>
        <Typography variant="body1" className="section-content">
          9Bricks and its affiliates are not liable for any direct, indirect, incidental, special, or consequential damages arising from the use or inability to use the website, even if advised of the possibility of such damages. The company does not warrant that the website will be uninterrupted, error-free, or free of viruses or other harmful components.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">5. Links to Third-Party Websites</Typography>
        <Typography variant="body1" className="section-content">
          This website may contain links to third-party websites. These links are provided solely as a convenience to you. 9Bricks does not endorse or control these third-party websites and is not responsible for their content or any damages or loss caused by their use.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">6. Privacy Policy</Typography>
        <Typography variant="body1" className="section-content">
          Your privacy is important to us. Please refer to our Privacy Policy, which explains how we collect, use, and safeguard your information when you access our website and services. By using our website, you consent to the practices described in the Privacy Policy.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">7. Modifications to Terms</Typography>
        <Typography variant="body1" className="section-content">
          9Bricks reserves the right to modify these Terms and Conditions at any time. Such modifications will become effective upon posting. Your continued use of the website following the posting of modified terms constitutes acceptance of the new terms.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">8. Governing Law</Typography>
        <Typography variant="body1" className="section-content">
          These Terms and Conditions are governed by the laws of the jurisdiction where 9Bricks is based. Any disputes arising from or relating to the use of the website will be resolved in the appropriate court located in this jurisdiction.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">9. Contact Information</Typography>
        <Typography variant="body1" className="section-content">
          For any questions or concerns regarding these Terms and Conditions, please contact us at: support@9bricks.com
        </Typography>
      </section>
    </Container>
  );
};

export default TermsAndConditions;
