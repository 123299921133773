import React from "react";
import { Container, Typography, Box } from "@mui/material";
import softwareImage from "../../assets/tavis-app.png";
import "./Products.scss";

const Products: React.FC = () => {
  return (
    <Container className="products-container">
      <Typography variant="h4" className="title">
        Introducing TAVIS
      </Typography>
      <Typography variant="h4" className="title">
        Your AI Personal Assistant
      </Typography>
      <br />
      <br />
      {/* Story Section */}
      <section className="section">
        <Typography variant="h6" className="section-title">
          The story behind TAVIS
        </Typography>
        <Typography variant="body1" className="section-content">
          TAVIS was born out of a desire to help individuals streamline their
          daily tasks and manage their work-life balance more effectively. In
          todays fast-paced world, we often struggle to keep up with the
          multitude of tasks, meetings, and deadlines. Recognizing this
          challenge, our team at 9Bricks set out to create a solution that could
          assist people in managing their routines seamlessly. TAVIS, the
          AI-powered personal assistant, was designed to provide intelligent,
          responsive, and personalized support in handling day-to-day tasks.
        </Typography>
      </section>

      <Box className="image-container">
        <img
          src={softwareImage}
          alt="Software Solutions"
          className="image"
          style={{ width: "60%" }}
        />
      </Box>
      <br />
      <br />
      <br />

      <section className="section">
        <Typography variant="h6" className="section-title">
          Current Features of TAVIS
        </Typography>
        <Typography variant="body1" className="section-content">
          TAVIS is equipped with a range of features aimed at simplifying your
          everyday tasks:
        </Typography>
        <ul className="feature-list">
          <li>
            <strong>Task Management:</strong> Organize your tasks with ease.
            Tavis categorizes, prioritizes, and provides reminders to keep you
            on track.
          </li>
          <li>
            <strong>Scheduling and Calendar Integration:</strong> TAVIS
            integrates with your calendar to schedule meetings, set reminders,
            and ensure you never miss an important event.
          </li>
          <li>
            <strong>Smart Reminders:</strong> TAVIS understands your patterns
            and sends timely reminders, helping you complete tasks on time.
          </li>
          <li>
            <strong>Data Analysis and Insights:</strong> TAVIS analyzes your
            daily activities and provides insights on productivity, helping you
            optimize your time.
          </li>
          <li>
            <strong>Personalized Recommendations:</strong> Based on your
            preferences and habits, TAVIS offers tailored suggestions for
            improving workflow efficiency.
          </li>
        </ul>
      </section>

      {/* Future Development Roadmap */}
      <section className="section">
        <Typography variant="h6" className="section-title">
          Future Development
        </Typography>

        <Box className="roadmap">
          <Box className="roadmap-item">
            <Typography variant="h6" className="roadmap-title">
              Expanding Functionalities and Integrations
            </Typography>
            <Typography variant="body2" className="roadmap-content">
              In the first year, we plan to expand TAVIS functionalities and
              integrations to make it an even more powerful assistant. We aim to
              integrate TAVIS with popular project management tools like Trello,
              Asana, and Slack, enabling users to manage both personal and
              professional tasks in one place. Additionally, we will introduce
              AI-driven insights that can identify productivity trends, helping
              users optimize their routines and reduce inefficiencies.
            </Typography>
          </Box>

          <Box className="roadmap-item">
            <Typography variant="h6" className="roadmap-title">
              Introducing Advanced AI Capabilities
            </Typography>
            <Typography variant="body2" className="roadmap-content">
              By the second year, we aim to leverage advanced AI capabilities to
              make TAVIS more intuitive and adaptive. With machine learning,
              TAVIS will be able to anticipate user needs based on past behavior
              and offer proactive assistance. We also plan to incorporate voice
              recognition and natural language processing, allowing users to
              interact with TAVIS using voice commands. Furthermore, TAVIS will
              have enhanced capabilities for real-time collaboration, assisting
              users in coordinating with team members seamlessly.
            </Typography>
          </Box>
        </Box>
      </section>

      {/* Conclusion */}
      <section className="section">
        <Typography variant="h6" className="section-title">
          Why Choose TAVIS?
        </Typography>
        <Typography variant="body1" className="section-content">
          TAVIS is more than just an AI assistant; it’s a tool designed to
          empower you to achieve more with less effort. Our commitment at
          9Bricks is to continually evolve TAVIS, keeping it at the forefront of
          AI and productivity technology. Whether you're a busy professional, a
          student, or someone looking to improve their work-life balance, TAVIS
          is here to help you every step of the way.
        </Typography>
      </section>
    </Container>
  );
};

export default Products;
