// src/pages/CookiePolicy.tsx
import React from "react";
import { Container, Typography } from "@mui/material";
import "./CookiePolicy.scss";

const CookiePolicy: React.FC = () => {
  return (
    <Container className="cookie-policy-container">
      <Typography variant="h4" className="title">Cookie Policy</Typography>
      
      <section className="section">
        <Typography variant="h6" className="section-title">1. Introduction</Typography>
        <Typography variant="body1" className="section-content">
          At 9Bricks, we use cookies to enhance your experience on our website. This Cookie Policy explains what cookies are, how we use them, and your options regarding their usage.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">2. What Are Cookies?</Typography>
        <Typography variant="body1" className="section-content">
          Cookies are small text files that are stored on your device when you visit our website. They help us remember your preferences, understand how you use our site, and improve your overall experience.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">3. Types of Cookies We Use</Typography>
        <Typography variant="body1" className="section-content">
          We use several types of cookies to optimize our website's functionality:
        </Typography>
        <ul>
          <li><strong>Essential Cookies:</strong> These cookies are necessary for the website to function correctly. They enable you to navigate our site and use essential features.</li>
          <li><strong>Performance Cookies:</strong> These cookies collect information about how you use our website, allowing us to improve site performance and user experience.</li>
          <li><strong>Functional Cookies:</strong> Functional cookies help us remember your preferences and provide enhanced, more personalized features.</li>
          <li><strong>Targeting/Advertising Cookies:</strong> These cookies are used to show you relevant advertisements based on your browsing behavior.</li>
        </ul>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">4. How We Use Cookies</Typography>
        <Typography variant="body1" className="section-content">
          9Bricks uses cookies to:
        </Typography>
        <ul>
          <li>Remember your preferences and settings.</li>
          <li>Analyze site usage to enhance website performance.</li>
          <li>Deliver relevant content and advertisements tailored to your interests.</li>
          <li>Improve security by detecting potential threats and unauthorized access.</li>
        </ul>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">5. Managing Your Cookie Preferences</Typography>
        <Typography variant="body1" className="section-content">
          You can control and manage cookies in your browser settings. Most browsers allow you to block or delete cookies, but this may affect your experience on our website.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">6. Third-Party Cookies</Typography>
        <Typography variant="body1" className="section-content">
          We may use third-party services that set their own cookies to help us understand website performance and deliver advertisements. These cookies are managed by third-party providers and are subject to their own privacy policies.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">7. Changes to This Cookie Policy</Typography>
        <Typography variant="body1" className="section-content">
          We may update our Cookie Policy periodically. We recommend reviewing this policy regularly to stay informed about how we use cookies.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">8. Contact Us</Typography>
        <Typography variant="body1" className="section-content">
          If you have any questions about our Cookie Policy, please contact us at:
        </Typography>
        <Typography variant="body1" className="contact-info">
          Email: support@9bricks.com <br />
          Address: District 12, Ho Chi Minh City, Vietnam
        </Typography>
      </section>
    </Container>
  );
};

export default CookiePolicy;
