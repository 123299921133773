// src/pages/PrivacyPolicy.tsx
import React from "react";
import { Container, Typography } from "@mui/material";
import "./PrivacyPolicy.scss";

const PrivacyPolicy: React.FC = () => {
  return (
    <Container className="privacy-policy-container">
      <Typography variant="h4" className="title">Privacy Policy</Typography>
      
      <section className="section">
        <Typography variant="h6" className="section-title">1. Introduction</Typography>
        <Typography variant="body1" className="section-content">
          At 9Bricks, we are committed to protecting the privacy and security of our clients and website visitors. This Privacy Policy outlines how we collect, use, and safeguard your information when you interact with our services.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">2. Information We Collect</Typography>
        <Typography variant="body1" className="section-content">
          We collect various types of information in connection with the services we provide, including:
        </Typography>
        <ul>
          <li>Personal Identification Information: Name, email address, phone number, etc.</li>
          <li>Technical Data: IP address, browser type, and device details.</li>
          <li>Usage Data: Information about how you use our website and services.</li>
        </ul>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">3. How We Use Your Information</Typography>
        <Typography variant="body1" className="section-content">
          9Bricks uses your information to:
        </Typography>
        <ul>
          <li>Provide and improve our software services.</li>
          <li>Respond to inquiries, questions, or support requests.</li>
          <li>Personalize user experience and optimize our website.</li>
          <li>Maintain security and prevent unauthorized access.</li>
        </ul>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">4. Sharing Your Information</Typography>
        <Typography variant="body1" className="section-content">
          We do not sell or trade your personal information. We may share your information with trusted third-party service providers to assist us in operating our website and services, provided they adhere to our privacy standards.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">5. Data Security</Typography>
        <Typography variant="body1" className="section-content">
          We implement a range of security measures to protect your personal data. These measures include encryption, access control, and regular security audits. However, please note that no system is entirely foolproof, and we cannot guarantee the absolute security of your information.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">6. Your Rights</Typography>
        <Typography variant="body1" className="section-content">
          You have the right to access, update, or delete your personal information held by us. If you wish to exercise these rights, please contact us using the contact information below.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">7. Changes to This Privacy Policy</Typography>
        <Typography variant="body1" className="section-content">
          9Bricks may update this Privacy Policy periodically to reflect changes in our practices. We encourage you to review this policy regularly to stay informed about how we protect your information.
        </Typography>
      </section>

      <section className="section">
        <Typography variant="h6" className="section-title">8. Contact Us</Typography>
        <Typography variant="body1" className="section-content">
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
        </Typography>
        <Typography variant="body1" className="contact-info">
          Email: support@9bricks.com <br />
          Address: District 12, Ho Chi Minh City, Vietnam
        </Typography>
      </section>
    </Container>
  );
};

export default PrivacyPolicy;
