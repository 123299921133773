import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import softwareImage from "../../assets/hero-9bricks.webp";
import "./SoftwareSolutions.scss";

const SoftwareSolutions: React.FC = () => {
  return (
    <Container className="software-solutions-container">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} className="text-column">
          <Typography variant="h3" className="title">
            Software Solutions
          </Typography>
          <Typography variant="h6" className="subtitle">
            Tailored Digital Solutions for Your Business
          </Typography>
          <Typography variant="body1" className="description">
            We are specialists in developing custom software tailored to your
            business needs. With agile methods at the core, we automate and
            streamline your business processes, transforming outdated workflows
            into efficient digital solutions.
          </Typography>
          <Typography variant="body1" className="description">
            Our team works closely with you to identify areas of improvement and
            deliver technology that replaces analog procedures, reduces
            unnecessary steps, and eliminates media breaks. Embrace the future
            with our modern and effective software solutions that drive your
            business forward.
          </Typography>
          <Typography variant="h6" className="subtitle">
            Why Choose Us?
          </Typography>
          <Typography variant="body1" className="description">
            - Agile development ensures quick iterations and flexibility <br />
            - Custom solutions tailored to your business goals <br />
            - Expertise in automation and digital transformation <br />-
            Transparent and collaborative approach
          </Typography>
        </Grid>

        {/* Phần Hình Ảnh Bên Phải */}
        <Grid item xs={12} md={6} className="image-column">
          <Box className="image-container">
            <img
              src={softwareImage}
              alt="Software Solutions"
              className="image"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SoftwareSolutions;
