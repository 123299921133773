import React from "react";
import { Typography } from "@mui/material";
import "./InfoCircles.scss";

const InfoCircles: React.FC = () => (
  <div className="info-circles">
    <div className="circle">
      <Typography variant="h3" className="circle-number">02+</Typography>
      <Typography variant="body2" className="circle-text">Years of expertise</Typography>
    </div>
    <div className="circle">
      <Typography variant="h3" className="circle-number">25+</Typography>
      <Typography variant="body2" className="circle-text">Customers</Typography>
    </div>
  </div>
);

export default InfoCircles;
