// src/components/Navbar.tsx

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo-9BR.png";
import "./Navbar.scss";

const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation(); // Get current route

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  // Helper function to check if a path is active
  const isActive = (path: string) => location.pathname === path;

  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      className="navbar"
    >
      <Toolbar className="toolbar">
        <div className="logo-placeholder">
          <Link to="/" style={{ textDecoration: "none", color: "#333" }}>
            <img src={logo} alt="9Bricks Logo" style={{ height: "40px" }} />
          </Link>
        </div>

        {/* Desktop Menu */}
        <nav className="nav-links">
          <Button
            color="inherit"
            component={Link}
            to="/software-solutions"
            className={isActive("/software-solutions") ? "active" : ""}
          >
            Expertise
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/products"
            className={isActive("/products") ? "active" : ""}
          >
            Products
          </Button>
          
          <Button
            color="inherit"
            component={Link}
            to="/our-journey"
            className={isActive("/our-journey") ? "active" : ""}
          >
            Our Journey
          </Button>

          <Button
            color="inherit"
            component={Link}
            to="/insights"
            className={isActive("/insights") ? "active" : ""}
          >
            Insights
          </Button>
          
          <Button
            color="inherit"
            component={Link}
            to="/jobs"
            className={isActive("/jobs") ? "active" : ""}
          >
            Jobs
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/about-us"
            className={isActive("/about-us") ? "active" : ""}
          >
            About us
          </Button>
          <Button color="inherit">EN ▼</Button>
        </nav>

        {/* Burger Menu Icon for Mobile */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          className="burger-menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {/* Drawer for Mobile */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        className="drawer"
      >
        <List>
          <ListItem
            component={Link}
            to="/software-solutions"
            onClick={toggleDrawer(false)}
            className={isActive("/software-solutions") ? "active" : ""}
          >
            <ListItemText primary="Expertise" />
          </ListItem>
          <ListItem
            component={Link}
            to="/products"
            onClick={toggleDrawer(false)}
            className={isActive("/products") ? "active" : ""}
          >
            <ListItemText primary="Products" />
          </ListItem>
          
          <ListItem
            component={Link}
            to="/our-journey"
            onClick={toggleDrawer(false)}
            className={isActive("/our-journey") ? "active" : ""}
          >
            <ListItemText primary="Our Journey" />
          </ListItem>

          <ListItem
            component={Link}
            to="/insights"
            onClick={toggleDrawer(false)}
            className={isActive("/insights") ? "active" : ""}
          >
            <ListItemText primary="Insights" />
          </ListItem>
          
          <ListItem
            component={Link}
            to="/jobs"
            onClick={toggleDrawer(false)}
            className={isActive("/jobs") ? "active" : ""}
          >
            <ListItemText primary="Jobs" />
          </ListItem>
          <ListItem
            component={Link}
            to="/about-us"
            onClick={toggleDrawer(false)}
            className={isActive("/about-us") ? "active" : ""}
          >
            <ListItemText primary="About us" />
          </ListItem>
        </List>
      </Drawer>

      {/* Divider */}
      <div className="footer-divider"></div>
    </AppBar>
  );
};

export default Navbar;
