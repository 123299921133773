// src/pages/Jobs.tsx
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import "./Jobs.scss";

const Jobs: React.FC = () => {
  return (
    <Container className="jobs-container">
      <Typography variant="h4" className="title">Join Our Team</Typography>
      <Typography variant="body1" className="intro">
        At 9Bricks, we believe in creating innovative solutions that empower businesses and individuals. While we're not currently hiring, we’re always open to meeting talented individuals who share our passion for technology and innovation.
      </Typography>

      <Box className="no-jobs-box">
        <Typography variant="h6" className="no-jobs-title">
          We’re not hiring at the moment
        </Typography>
        <Typography variant="body2" className="no-jobs-content">
          Currently, we do not have any open positions. Please check back later or feel free to connect with us for future opportunities. We appreciate your interest in joining 9Bricks and look forward to welcoming exceptional talent in the near future.
        </Typography>
      </Box>
    </Container>
  );
};

export default Jobs;
